.roi-calculator {
  background: rgba(18, 18, 18, 0.95);
  border-radius: 1rem;
  padding: 3rem 2rem;
  margin: 2rem auto;
  max-width: 1200px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.roi-header {
  text-align: center;
  margin-bottom: 3rem;
}

.roi-header h2 {
  font-size: 2.5rem;
  color: var(--primary);
  margin: 1rem 0;
}

.roi-header p {
  color: var(--text-light);
  font-size: 1.1rem;
}

.roi-icon {
  color: var(--primary);
  width: 48px;
  height: 48px;
}

.calculator-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: start;
}

.input-section,
.results-section {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 0.75rem;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.input-section h3,
.results-section h3 {
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.input-group {
  margin-bottom: 1.5rem;
}

.input-group label {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--text-light);
  font-size: 1rem;
}

.input-group input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  padding: 0.75rem;
  color: var(--text);
  font-size: 1rem;
  width: 100%;
  transition: border-color 0.2s;
}

.input-group input:focus {
  outline: none;
  border-color: var(--primary);
}

.results-grid {
  display: grid;
  gap: 1.5rem;
}

.result-card {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0.75rem;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.result-icon {
  color: var(--primary);
  width: 24px;
  height: 24px;
}

.result-content h4 {
  color: var(--text-light);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.result-content p {
  color: var(--text);
  font-size: 1.5rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .roi-calculator {
    padding: 2rem 1rem;
  }

  .calculator-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .roi-header h2 {
    font-size: 2rem;
  }
}
